import imagem1 from "../../assets/images/profile/1.png"
import imagem2 from "../../assets/images/profile/2.png"
import imagem3 from "../../assets/images/profile/3.png"
import imagem4 from "../../assets/images/profile/4.png"
import imagem5 from "../../assets/images/profile/5.png"

export const myMembersList = [
  {
    name: "Carlos Souza",
    position: "Secretária",
    pics: imagem1,
  },
  {
    name: "Maitê Canto",
    position: "Administrativo",
    pics: imagem2,
  },
  {
    name: "Abelardo Oliveira",
    position: "Administrativo",
    pics: imagem3,
  },
  {
    name: "Maira Hamada",
    position: "Assistente",
    pics: imagem4,
  },
  {
    name: "Nilton Gomes",
    position: "Assistente",
    pics: imagem5,
  },
];
<template>
  <div class="limit p-3 pt-0">
    <div class="p-3">
      <span class="title-color fs-10 text-bold">Minha equipe</span>
      <div class="container-service mt-4" style="margin-bottom: 28px">
        <div class="left">
          <Button class="btn principal" @click="addMember">
            Adicionar Membro
          </Button>
          <div class="btns-frequency">
            <Button
              class="btn"
              v-for:="item in btnFilter"
              :class="isFilter(item.label)"
              @click="changeFilter(item)"
            >
              {{ item.label }}
            </Button>
          </div>
        </div>

        <input class="buscar" placeholder="Buscar" />
      </div>

      <div class="surface-card p-5" style="display: none">
        <div class="service-content">
          <i class="fas fa-medkit icon"></i>
          <p>Nenhum membro cadastrado.</p>
        </div>
        <div class="service-button">
          <Button class="button" label="Adicionar Membro" @click="add" />
        </div>
      </div>
      <div
        v-for="(member, index) in myMembersList"
        :key="index"
        class="card-frequency flex justify-content-between align-items-center"
        :style="switchActive[index] ? 'opacity: 1' : 'opacity: .5'"
      >
        <div class="flex">
          <img
            :style="{ width: '90px', height: '90px', borderRadius: '8px' }"
            :src="member.pics"
          />
          <div
            class="flex fd-column mt-2 ml-1"
            :style="{
              width: '380px',
              marginRight: '100px',
            }"
          >
            <div>
              <span class="h1-name mr-1">{{ member.name }}</span>
              <img
                :style="{ width: '12px', height: '13px', marginTop: '6px' }"
                src="../../assets/images/editIcon.png"
                class="ml-2 pointer"
                @click="editMemberData"
              />
            </div>
            <div class="color-gray__3 fs-4">{{ member.position }}</div>
          </div>
        </div>
        <div class="d-flex mr-4">
          <span class="mr-3 text-bold fs-6">{{switchActive[index] ? 'Ativo' : 'Inativo'}}</span>
          <InputSwitch v-model="switchActive[index]" />
        </div>
      </div>
    </div>

    <Dialog
      header="Editar Perfil de Gerenciamento"
      v-model:visible="displayDialogChangePermissions"
      :breakpoints="{
        '960px': '75vw',
        borderRadius: '8px',
      }"
      :style="{
        width: '402px',
        borderRadius: '8px',
        boxShadow: '0px 0px 16px rgba(61, 71, 102, 0.08)',
        backdropFilter: 'blur(8px)',
      }"
    >
      <div class="mt-3 d-flex fd-column">
        <Dropdown
          class="std-normal mt-3 flex ai-center text-bold"
          v-model="selectedLevel"
          :options="levelsList"
          optionLabel="name"
          placeholder="Selecione o nível"
        />
        <span class="mt-3 text-bold color-gray__3">Nível de Acesso</span>
        <div class="mt-3">
          <div class="field-checkbox">
            <Checkbox id="binary" v-model="checked" :binary="true" />
            <label for="binary">Serviços Oferecidos</label>
          </div>
          <div>
            <div class="field-checkbox">
              <Checkbox id="binary" v-model="checked" :binary="true" />
              <label for="binary">Locais de Atendimento</label>
            </div>
            <div class="field-checkbox">
              <Checkbox id="binary" v-model="checked" :binary="true" />
              <label for="binary">Horários Disponibilizados</label>
            </div>
            <div class="field-checkbox">
              <Checkbox id="binary" v-model="checked" :binary="true" />
              <label for="binary">Histórico de Horários Disponibilizados</label>
            </div>
            <div class="field-checkbox">
              <Checkbox id="binary" v-model="checked" :binary="true" />
              <label for="binary">Agenda Diária</label>
            </div>
            <div class="field-checkbox">
              <Checkbox id="binary" v-model="checked" :binary="true" />
              <label for="binary">Agenda Semanal</label>
            </div>
            <div class="field-checkbox">
              <Checkbox id="binary" v-model="checked" :binary="false" />
              <label for="binary">Agenda Mensal</label>
            </div>
            <div class="field-checkbox">
              <Checkbox id="binary" v-model="checked" :binary="true" />
              <label for="binary">Atendimentos Agendados</label>
            </div>
            <div class="field-checkbox">
              <Checkbox id="binary" v-model="checked" :binary="true" />
              <label for="binary">Atendimentos Realizados</label>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="flex justify-content-end align-items-center h-full">
          <Button class="addMember-button" label="Salvar" @click="addMember" />
        </div>
      </template>
    </Dialog>
    <Dialog
      header="Adicionar Membro da Equipe"
      class="modal"
      v-model:visible="displayDialog"
      :breakpoints="{
        '960px': '75vw',
        borderRadius: '8px',
      }"
      :style="{
        width: '402px',
        borderRadius: '8px',
        boxShadow: '0px 0px 16px rgba(61, 71, 102, 0.08)',
        backdropFilter: 'blur(8px)',
      }"
    >
      <div class="mt-3 d-flex fd-column">
        <InputText class="std-top" placeholder="Email" />
        <InputText class="std-middle" placeholder="Nome" />
        <InputText class="std-bottom" placeholder="Sobrenome" />
        <span class="mt-3 text-bold color-gray__3"
          >Perfil de Gerenciamento</span
        >
        <Dropdown
          class="std-normal mt-3 flex ai-center text-bold"
          v-model="selectedLevel"
          :options="levelsList"
          optionLabel="name"
          placeholder="Selecione o nível"
        />
        <span class="mt-3">Esse perfil pode acessar e gerenciar:</span>
        <ul>
          <li>Horários Disponibilizados</li>
          <li>Atendimentos Agendados</li>
        </ul>
        <Button
          class="p-button-text as-center"
          label="Editar Perfil de Gerenciamento"
          style="width: 300px"
          @click="editLevelPermissions"
        />
      </div>
      <template #footer>
        <div class="flex justify-content-end align-items-center h-full">
          <Button class="addMember-button" label="Salvar" @click="addMember" />
        </div>
      </template>
    </Dialog>
    <Dialog
      header="Editar Dados do Membro da Equipe"
      class="modal"
      v-model:visible="displayDialogEditMemberData"
      :breakpoints="{
        '960px': '75vw',
        borderRadius: '8px',
      }"
      :style="{
        width: '402px',
        borderRadius: '8px',
        boxShadow: '0px 0px 16px rgba(61, 71, 102, 0.08)',
        backdropFilter: 'blur(8px)',
      }"
    >
      <div class="mt-3 d-flex fd-column">
        <InputText class="std-top" placeholder="Email" />
        <InputText class="std-middle" placeholder="Nome" />
        <InputText class="std-bottom" placeholder="Sobrenome" />
        <span class="mt-3 text-bold color-gray__3"
          >Perfil de Gerenciamento</span
        >
        <Dropdown
          class="std-normal mt-3 flex ai-center text-bold"
          v-model="selectedLevel"
          :options="levelsList"
          optionLabel="name"
          placeholder="Selecione o nível"
        />
        <span class="mt-3">Esse perfil pode acessar e gerenciar:</span>
        <ul>
          <li>Horários Disponibilizados</li>
          <li>Atendimentos Agendados</li>
        </ul>
        <Button
          class="p-button-text as-center"
          label="Editar Perfil de Gerenciamento"
          style="width: 300px"
          @click="editLevelPermissions"
        />
      </div>
      <template #footer>
        <div class="flex justify-content-end align-items-center h-full">
          <Button class="addMember-button" label="Salvar" @click="editMember" />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref } from "vue";
let displayDialog = ref(false);
import { myMembersList } from "./myMembersList";

const addMember = () => {
  displayDialog.value = true;
};

let displayDialogChangePermissions = ref(false);
const editLevelPermissions = () => {
  displayDialogChangePermissions.value = true;
};
let displayDialogEditMemberData = ref(false);
const editMemberData = () => {
  displayDialogEditMemberData.value = true;
};

let selectedLevel = ref({ name: "Assistente" });
const levelsList = [
  { name: "Assistente" },
  // { name: "Estados Unidos", code: "+1" },
];

const getPic = (index) => {
  return "../assets/images/" + this.pics[index] + ".png";
};

export default {
  setup() {
    const switchActive = ref([true, true, true, false, false]);
    const filter = ref("Todos");
    const btnFilter = [
      { label: "Todos" },
      { label: "Ativos" },
      { label: "Inativos" },
    ];
    const isFilter = (item) => {
      if (item !== filter.value) {
        return "inative";
      }
    };

    const changeFilter = (item) => {
      filter.value = item.label;
      // router.push({ path: item.to });
    };
    return {
      filter,
      btnFilter,
      isFilter,
      changeFilter,
      switchActive,
      getPic,
      addMember,
      displayDialog,
      selectedLevel,
      levelsList,
      editLevelPermissions,
      displayDialogChangePermissions,
      editMemberData,
      displayDialogEditMemberData,
      myMembersList,
    };
  },
};
</script>

<style scoped lang="scss">
.modal {
  .p-dialog-header {
    box-shadow: 0px 0px 16px rgba(61, 71, 102, 0.08) !important;
    backdrop-filter: blur(8px) !important;
    .mb-2 {
      font-size: 18px;
      line-height: 21.48px;
      margin: 0 auto;
    }
  }
}

.input-small {
  width: 183px;
  height: 40px;
  background: #ffffff;
  text-align: right;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
}

.surface-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-selectbutton .p-button.p-highlight {
  color: white !important;
}

.addMember-button {
  height: 35px !important;
}

.myServices-card {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  justify-content: space-between;
}

.service-content {
  display: flex;
  margin: 0 auto;
  color: #a2c0d4;
}

.tag-color {
  width: 4px;
  height: 10px;
  margin-right: 3px;
  border-radius: 2px;
}

.service-button {
  margin-top: 10px;

  .button {
    height: 40px !important;
    border-radius: 8px !important;
    padding: 12px 16px;
  }
}

.icon {
  margin-top: 2px;
  margin-right: 10px;
}

.addService-button {
  height: 35px !important;
}

.p-selectbutton {
  width: 305px !important;
  display: flex;
}

.p-button {
  border: none !important;
}

.card-frequency {
  width: 100%;
  border-radius: 8px;
  background: #fff;
  margin: 8px 0;
  img:nth-child(1) {
    height: 86px;
    width: 86px;
    border-radius: 10px;
    padding: 5px;
  }
}
.btn {
  width: max-content;
  height: 40px;
}
.principal {
  white-space: nowrap;
  padding: 0px 32px;
}
.buscar {
  width: 100%;
  height: 40px !important;
  border-radius: 8px;
  border: none;
  padding: 20px;
  color: #bdbdbd;
  &::-webkit-input-placeholder {
    color: #bdbdbd;
  }
}
.limit {
  max-width: 100%;
  .container-service {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .row-service {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: calc(95% - 200px);
      max-width: 650px;
    }
    input {
      width: 200px;
    }
    .left {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 220px);
      gap: 10px;
      @media (max-width: 970px) {
        width: 100%;
        margin-bottom: 10px;
      }
      @media (max-width: 500px) {
        flex-direction: column;
        margin-bottom: 10px;
      }
    }
  }
}
.btns-frequency {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  .inative {
    background: #fff !important;
    border: 1px solid #fff;
    color: #828282;
  }
  .btn:nth-child(1) {
    background: #ff6a33;
    padding: 0px 32px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .btn:nth-child(2) {
    background: #ff6a33;
    padding: 0px 32px;
    border-radius: 0px;
  }
  .btn:nth-child(3) {
    background: #ff6a33;
    padding: 0px 32px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  @media (max-width: 995px) {
    justify-content: flex-start;
  }
  @media (max-width: 456px) {
    justify-content: flex-start;
    margin-bottom: 10px;
  }
}
.h1-name {
  margin: 0;
  color: #2d313d;
  font-size: 15px;
  line-height: 17px;
  font-weight: 600;
}
.pointer{
  cursor: pointer;
}
input{
  height: 50px !important;
}
.std-normal{
  height: 50px !important;
}
</style>
